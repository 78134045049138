import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import '~/styles';
import { Navbar } from '~/components/Navbar';

const TemplateWrapper = ({ navbarData = null, children }) => (
  <div id="outer">
    <Helmet>
      <html lang="es" />
      <meta name="keywords" content="produccion audiovisual, fotografia" />
    </Helmet>
    <Navbar data={navbarData} />
    <main id="main">{children}</main>
  </div>
);

export const query = graphql`
  fragment LayoutFragment on Query {
    navbarData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "navbar" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            menuItems {
              label
              linkType
              linkURL
            }
            instagramUrl
            vimeoUrl
          }
        }
      }
    }
  }
`;

export default TemplateWrapper;
